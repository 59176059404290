import request from '@/utils/request'


// 查询常见问题分类列表
export function listType(query) {
  return request({
    url: '/platform/type/list',
    method: 'get',
    params: query
  })
}

// 查询常见问题分类分页
export function pageType(query) {
  return request({
    url: '/platform/type/page',
    method: 'get',
    params: query
  })
}

// 查询常见问题分类详细
export function getType(data) {
  return request({
    url: '/platform/type/detail',
    method: 'get',
    params: data
  })
}

// 新增常见问题分类
export function addType(data) {
  return request({
    url: '/platform/type/add',
    method: 'post',
    data: data
  })
}

// 修改常见问题分类
export function updateType(data) {
  return request({
    url: '/platform/type/edit',
    method: 'post',
    data: data
  })
}

// 常见问题分类状态修改
export function editTypeStatus(id, status) {
  const statusData = {
    id,
    status
  }
  return request({
    url: '/platform/type/status',
    method: 'post',
    data: statusData
  })
}

// 删除常见问题分类
export function delType(data) {
  return request({
    url: '/platform/type/delete',
    method: 'post',
    data: data
  })
}
